import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SplashScreenService } from '@core/modules/splash-screen/splash-screen.service';
import { Subscription } from 'rxjs';

import { FrontedVersionCheckService } from '@core/services/frontend-version-check.service';
import { NavigationProgressService } from '@services/navigation-progress.service';
import { TitleService } from '@services/title.service';
import { TranslationService } from '@services/translation.service';
import { UserViewSettingsService } from '@services/user-view-settings.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[pps-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = [];
  private readonly _frontedVersionCheckService = inject(FrontedVersionCheckService);

  constructor(
    private readonly _translationService: TranslationService,
    private readonly _titleService: TitleService,
    private readonly _userViewSettingsService: UserViewSettingsService,
    private readonly _splashScreenService: SplashScreenService,
    private readonly _navigationProgressService: NavigationProgressService,
    private readonly _router: Router,
  ) {}

  ngOnInit() {
    const routerSubscription = this._router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this._navigationProgressService.changeIsInProgress(true);
      } else if (event instanceof NavigationEnd) {
        this._navigationProgressService.changeIsInProgress(false);
        this._splashScreenService.hide();
        window.scrollTo(0, 0);
      } else if (event instanceof NavigationCancel) {
        this._navigationProgressService.changeIsInProgress(false);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
